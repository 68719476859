import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Contributors from '../../components/Contributors';
import MultiVideoPlayer from '../../components/MultiVideoPlayer';
import Card from '../../components/Card';
import HeroArchive from '../../components/HeroArchive';
import Button from '../../components/Button';
import Icon from '../../components/Icon';

const VFTPage = () => (
  <Layout title="VFT">
    <HeroArchive
      className="hero-archive--vft"
      banner={true}
      heroBanner="The Power of Cooperatives Virtual Field Trip"
    >
      <MultiVideoPlayer
        videos={[
          {
            guidSrc: '2ae59d60-c645-4c8b-aa94-cd0c3adfa126',
            poster: 'img-vft-still-frame.jpg',
          },
          {
            guidSrc: 'cb22682f-9e3f-4598-93df-c899a3da27f0',
            poster: 'img-vft-slider-1.png',
          },
          {
            guidSrc: '2f1fb8f4-c6a9-40aa-b8b5-b7d382d1a000',
            poster: 'img-vft-slider-2.png',
          },
          {
            guidSrc: '71d9fc2d-250d-48fa-adf2-c019afeec5fe',
            poster: 'img-vft-slider-3.png',
          },
          {
            guidSrc: '19e74e0d-d048-4fac-bccf-12a5a06e7859',
            poster: 'img-vft-slider-4.png',
          },
          {
            guidSrc: '6850506a-277f-422f-a5b4-9fc6d4b40ffe',
            poster: 'img-vft-slider-5.png',
          },
          {
            guidSrc: '87293fee-cde2-4989-aa9b-b35bc205277a',
            poster: 'img-vft-slider-6.png',
          },
        ]}
      />
    </HeroArchive>
    <Section className="mb-2">
      <Container>
        <Row>
          <Column medium={8}>
            <h2>Exploring the World of Agricultural Cooperatives</h2>
            <h3>About</h3>
            <p>
              Cooperative Minds is dedicated to empowering students, educators,
              and communities with no-cost learning resources designed for any
              learning environment. Give students from every background the
              skills to collaborate with likeminded individuals and accomplish
              goals together.
            </p>
            <h3>Food for Thought</h3>
            <p>
              Empower students to start cultivating their own success by
              introducing them to the abundant potential of the{' '}
              <strong>agricultural co-op business model</strong>. Discover the
              diverse array of careers that intersect to help ensure the foods
              and products we depend on get to our communities and across the
              globe every day.
            </p>
          </Column>
          <Column medium={4}>
            <Card
              title="Educator Guide"
              image="img-vft-card-educator-guide.jpg"
              headline="Virtual Field Trip Educator Guide"
              subhead="Grades 9–12"
              duration="2:45"
            >
              <p>
                Inspire students before, during, and after watching the Virtual
                Field Trip using this companion guide, featuring student
                activities, a lesson plan and more.
              </p>
              <div className="card__button">
                <Button className="icon--download" target="blank" to="../pdfs/CHS-VFT-Guide-V1.0.pdf">
                  <Icon name="download" marginRight />
                  Learn More
                </Button>
              </div>
            </Card>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="vft__quote mb-2">
      <Container>
        <blockquote>
          “We all do better when we work together to overcome challenges.”
          <br />
          <cite>– Shawn Feikema, Farm Owner</cite>
        </blockquote>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container>
        <Row>
          <Column>
            <h2>Featured Contributors</h2>
            <p>
              Learn how diverse teammates with a wide variety of skills and
              backgrounds work harmoniously within agricultural co-ops.
            </p>
          </Column>
        </Row>
        <Contributors />
      </Container>
    </Section>
  </Layout>
);

export default VFTPage;
